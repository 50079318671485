import React, { useEffect, useState } from 'react';
import './MeusCupons.css';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function MeusCupons() {
  const [cupons, setCupons] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCupons = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'clientes', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const cuponsArray = Object.values(userData.cuponsGerados || {});
          setCupons(cuponsArray);
        }
      }
    };

    fetchCupons();
  }, []);

  return (
    <div className="meus-cupons-container">
      <button onClick={() => navigate('/inicio')} className="voltar-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
      </button>
      <div className="content-box">
        <h1>Meus Cupons</h1>
        <div className="cupons-lista">
          {cupons.map((cupom, index) => (
            <div key={index} className="cupom-item">
              <p><strong>{cupom.numero}</strong></p>
              <p>Data: {new Date(cupom.data).toLocaleDateString()}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MeusCupons;

