import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-custom">
      <div className="footer-links">
        <Link to="/termos">Termos e Condições</Link>
        <span className="divider"> | </span>
        <Link to="/privacidade">Política de Privacidade</Link>
      </div>
      <hr className="footer-line" />
      <div className="footer-info">
        <p>Copyright {new Date().getFullYear()} - Postos 3R - Todos os Direitos Reservados.</p>
        <p>
          Desenvolvido por: <a href="https://appesistemas.ligaya.com.br">
            <img src={require('./ligaya.png')} alt="Ligaya" className="ligaya-logo" />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;


