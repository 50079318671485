import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Certifique-se de configurar corretamente seu firebaseConfig
import { collection, doc, setDoc, getDoc, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faRedo, faSpinner, faArrowLeft, faFrown } from '@fortawesome/free-solid-svg-icons';
import './RealizarSorteio.css';

const RealizarSorteio = () => {
  const [numbers, setNumbers] = useState(Array(6).fill(''));
  const [winner, setWinner] = useState(null);
  const [noWinners, setNoWinners] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [drawn, setDrawn] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const sorteioDocRef = doc(collection(db, 'sorteioeganhador'), 'current');
      const sorteioDoc = await getDoc(sorteioDocRef);
      if (sorteioDoc.exists()) {
        const data = sorteioDoc.data();
        setNumbers(data.numeros || Array(6).fill(''));
        setWinner(data.winner || null);
        setAttempts(data.attempts || 0);
        setMessage(data.message || '');
        setDrawn(!!data.numeros?.length);
      }
    };

    fetchData();
  }, []);

  const handleNumberChange = (index, value) => {
    const newNumbers = [...numbers];
    newNumbers[index] = value;
    setNumbers(newNumbers);
  };

  const handleDraw = async () => {
    if (drawn) {
      setMessage('Sorteio já realizado. Para sortear novamente, Zere o Sorteio.');
      return;
    }

    if (numbers.some(number => number === '')) {
      alert('Por favor, insira todos os números antes de sortear.');
      return;
    }

    setLoading(true);
    const currentAttempt = attempts + 1;
    setAttempts(currentAttempt);
    console.log(`Tentativa ${currentAttempt}: Números inseridos: ${numbers.join(', ')}`);

    const sorteioDocRef = doc(collection(db, 'sorteioeganhador'), 'current');
    await setDoc(sorteioDocRef, { numeros: numbers, winner: null, attempts: currentAttempt });

    let foundWinner = null;

    // Combinar os números inseridos em um único string para formar o ID do documento a ser buscado
    const combinedNumbers = numbers.join('');

    console.log(`Buscando documento com ID: ${combinedNumbers}`);
    const numberDocRef = doc(collection(db, 'cupons'), combinedNumbers);
    const numberDoc = await getDoc(numberDocRef);
    if (numberDoc.exists()) {
      console.log(`Número encontrado: ${combinedNumbers}`);
      const winnerData = numberDoc.data();
      foundWinner = {
        nome: winnerData.nome,
        email: winnerData.email,
        profileImageUrl: winnerData.profileImageUrl,
        data: winnerData.data,
        numero: winnerData.numero,
        modalidade: `Ganhador pela ${currentAttempt}ª sequência da loteria`
      };

      // Remover campos indefinidos antes de salvar
      Object.keys(foundWinner).forEach(key => {
        if (foundWinner[key] === undefined) {
          delete foundWinner[key];
        }
      });
    } else {
      console.log(`Número não encontrado: ${combinedNumbers}`);
    }

    if (foundWinner) {
      console.log('Ganhador encontrado:', foundWinner);
      await setDoc(sorteioDocRef, { winner: foundWinner, message: 'Ganhador encontrado!' }, { merge: true });
      setWinner(foundWinner);
      setNoWinners(false);
      setMessage('Ganhador encontrado!');
    } else {
      console.log('Nenhum ganhador encontrado nesta tentativa.');
      await setDoc(sorteioDocRef, { attempts: currentAttempt }, { merge: true });

      if (currentAttempt < 5) {
        setNoWinners(true);
        setMessage(`Não houve ganhador pela ${currentAttempt}ª sequência sorteada pela loteria federal. Insira a ${currentAttempt + 1}ª sequência sorteada.`);
      } else {
        setShowModal(true);
      }
    }
    setLoading(false);
  };

  const handleRandomDraw = async () => {
    setShowModal(false);
    setLoading(true);
    setMessage('Não houveram ganhadores pelo sorteio da loteria federal. Realizando sorteio aleatório pelo sistema...');

    console.log('Realizando sorteio aleatório de cupons.');
    const cuponsSnapshot = await getDocs(collection(db, 'cupons'));
    const cupons = [];
    cuponsSnapshot.forEach((doc) => {
      cupons.push({ id: doc.id, ...doc.data() });
    });

    if (cupons.length > 0) {
      const randomIndex = Math.floor(Math.random() * cupons.length);
      const randomWinner = cupons[randomIndex];
      console.log('Ganhador sorteado aleatoriamente:', randomWinner);
      const sorteioDocRef = doc(collection(db, 'sorteioeganhador'), 'current');
      await setDoc(sorteioDocRef, { winner: { ...randomWinner, modalidade: 'Ganhador por Sorteio Aleatório' }, message: 'Ganhador sorteado aleatoriamente!' }, { merge: true });
      setWinner({ ...randomWinner, modalidade: 'Ganhador por Sorteio Aleatório' });
      setMessage('Ganhador sorteado aleatoriamente!');
    }
    setLoading(false);
  };

  const handleNoRandomDraw = () => {
    setShowModal(false);
    setNoWinners(true);
    setMessage('Não houveram ganhadores pela Loteria Federal!');
  };

  const handleReset = async () => {
    const sorteioDocRef = doc(collection(db, 'sorteioeganhador'), 'current');
    await setDoc(sorteioDocRef, { numeros: [], winner: null, attempts: 0, message: '' });
    setWinner(null);
    setNumbers(Array(6).fill(''));
    setNoWinners(false);
    setAttempts(0);
    setMessage('');
    setDrawn(false);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', options).replace(',', ' às');
  };

  return (
    <div className="sorteio-container">
      <button className="back-button" onClick={() => navigate('/dashrestrito')}>
        <FontAwesomeIcon icon={faArrowLeft} className="button-icon" />
        Voltar para Dashboard
      </button>
      <div className="sorteio-content-box">
        <h2>Realizar Sorteio</h2>
        <div className="number-inputs">
          {numbers.map((number, index) => (
            <input
              key={index}
              type="text"
              value={number}
              onChange={(e) => handleNumberChange(index, e.target.value)}
              className="number-input"
              maxLength={1}
              placeholder={`Número ${index + 1}`}
            />
          ))}
        </div>
        <button className="sorteio-button" onClick={handleDraw} disabled={loading || drawn}>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} className="button-icon spinning" />
          ) : (
            <FontAwesomeIcon icon={faSort} className="button-icon" />
          )}
          Sortear
        </button>

        {message && !showModal && (
          <div className="message">
            <p>{message}</p>
          </div>
        )}

        {winner && (
          <div className="winner-info">
            <h3>Ganhador</h3>
            <p>Nome: {winner.nome}</p>
            <p>Email: {winner.email}</p>
            <p>Data do Cupom: {formatDate(winner.data)}</p>
            <p>Número do Cupom: {winner.numero}</p>
            <p>Modalidade de Ganho: {winner.modalidade}</p>
            {winner.profileImageUrl && <img src={winner.profileImageUrl} alt="Foto do Ganhador" className="winner-image" />}
          </div>
        )}

        {noWinners && attempts === 5 && !showModal && (
          <div className="no-winners-message">
            <p><FontAwesomeIcon icon={faFrown} /> Não houveram ganhadores pela Loteria Federal!</p>
          </div>
        )}

        <button className="reset-button" onClick={handleReset} disabled={loading}>
          <FontAwesomeIcon icon={faRedo} className="button-icon" />
          Zerar Sorteio
        </button>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Não houveram ganhadores pelo sorteio da loteria federal!</h2>
            <button className="modal-button modal-button-green" onClick={handleRandomDraw}>
              <FontAwesomeIcon icon={faSort} className="button-icon" />
              Realizar Sorteio Aleatório
            </button>
            <button className="modal-button modal-button-yellow" onClick={handleNoRandomDraw}>
              <FontAwesomeIcon icon={faFrown} className="button-icon" />
              Não
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RealizarSorteio;

