import React, { useEffect, useState } from 'react';
import './Ganhador.css';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEye, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom';

function Ganhador() {
  const [winner, setWinner] = useState(null);
  const [winnerDetails, setWinnerDetails] = useState(null);
  const [showFullCpf, setShowFullCpf] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWinner = async () => {
      try {
        const winnerCollection = collection(db, 'sorteioeganhador');
        const winnerSnapshot = await getDocs(winnerCollection);
        if (!winnerSnapshot.empty) {
          const winnerData = winnerSnapshot.docs[0].data().winner;
          setWinner(winnerData);

          if (winnerData && winnerData.email) {
            const clientsCollection = collection(db, 'clientes');
            const clientQuery = query(clientsCollection, where('email', '==', winnerData.email));
            const clientSnapshot = await getDocs(clientQuery);
            if (!clientSnapshot.empty) {
              setWinnerDetails(clientSnapshot.docs[0].data());
            }
          }
        }
      } catch (error) {
        console.error('Erro ao buscar dados do ganhador:', error);
      }
    };

    fetchWinner();
  }, []);

  const handleShowFullCpf = () => {
    setShowFullCpf(!showFullCpf);
  };

  const openWhatsApp = (tel) => {
    const whatsappUrl = `https://wa.me/${tel}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="ganhador-container">
      <button onClick={() => navigate('/dashrestrito')} className="voltar-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Dashboard
      </button>
      <div className="content-box">
        <h1>Ganhador do Sorteio</h1>
        {winner ? (
          <div className="winner-info">
            <p><strong>Nome:</strong> {winner.nome}</p>
            <p><strong>Email:</strong> {winner.email}</p>
            <p><strong>Data do Cupom:</strong> {new Date(winner.data).toLocaleDateString()}</p>
            <p><strong>Número do Cupom:</strong> {winner.numero}</p>
            <p><strong>Modalidade:</strong> {winner.modalidade}</p>
            {winnerDetails && (
              <div className="winner-additional-info">
                {winnerDetails.profileImageUrl && (
                  <img
                    src={winnerDetails.profileImageUrl}
                    alt="Profile"
                    className="winner-image"
                  />
                )}
                <p><strong>CPF:</strong> {showFullCpf ? winnerDetails.cpf : `****${winnerDetails.cpf.slice(-4)}`}
                  <button onClick={handleShowFullCpf} className="show-cpf-button">
                    <FontAwesomeIcon icon={faEye} /> {showFullCpf ? 'Esconder' : 'Mostrar'} CPF Completo
                  </button>
                </p>
                {winnerDetails.tel && (
                  <p>
                    <strong>Telefone:</strong> {winnerDetails.tel}
                    <button onClick={() => openWhatsApp(winnerDetails.tel)} className="whatsapp-button">
                      <FontAwesomeIcon icon={faWhatsapp} /> Avisar Ganhador
                    </button>
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="no-winner">
            <p>Ganhador ainda não revelado, realize o sorteio para revelar o ganhador.</p>
            <button onClick={() => navigate('/realizar-sorteio')} className="sorteio-button">
              <FontAwesomeIcon icon={faTrophy} /> Realizar sorteio
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Ganhador;


