import React, { useEffect, useState } from 'react';
import './HistoricoDeNotas.css';
import { auth, db, storage } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function HistoricoDeNotas() {
  const [notas, setNotas] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNotas = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'clientes', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const notasArray = Object.values(userData.abastecimentos || {});
          setNotas(notasArray);
        }
      }
    };

    fetchNotas();
  }, []);

  const handleVerNota = async (imagemUrl) => {
    try {
      const url = await getDownloadURL(ref(storage, imagemUrl));
      window.open(url, '_blank');
    } catch (error) {
      console.error("Erro ao buscar imagem:", error);
    }
  };

  return (
    <div className="historico-de-notas-container">
      <button onClick={() => navigate('/inicio')} className="voltar-button">
        <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
      </button>
      <div className="content-box">
        <h1>Histórico de Notas</h1>
        <div className="notas-lista">
          {notas.map((nota, index) => (
            <div key={index} className="nota-item">
              <img src={nota.imagem} alt={`Nota ${index + 1}`} className="nota-thumbnail" />
              <div className="nota-info">
                <p><strong>Valor:</strong> R${nota.valor.toFixed(2)}</p>
                <p><strong>Posto:</strong> {nota.posto}</p>
                <p><strong>Data:</strong> {new Date(nota.data).toLocaleDateString()}</p>
              </div>
              <button onClick={() => handleVerNota(nota.imagem)} className="ver-nota-button">
                <FontAwesomeIcon icon={faEye} /> Ver Nota
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HistoricoDeNotas;



