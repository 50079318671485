import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png';
import mobileLogo from './bheader.png';  // Importe a imagem do logo para mobile
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    setIsMenuVisible(false);
  };

  return (
    <header className="header">
      <Link to="/">
        <img src={logo} alt="Logo" className="logo desktop-logo" />
        <img src={mobileLogo} alt="Logo" className="logo mobile-logo" />
      </Link>
      <button onClick={toggleMenu} className="menu-button">
        {isMenuVisible ? 'X' : '☰'}
      </button>
      {isMenuVisible && (
        <nav className="menu">
          <button onClick={toggleMenu} className="close-menu-button">X</button>
          <Link to="/" className="menu-link" onClick={closeMenu}>Início</Link>
          <Link to="/suporte" className="menu-link" onClick={closeMenu}>Suporte</Link>
          <Link to="/termos" className="menu-link" onClick={closeMenu}>Termos e Condições</Link>
          <Link to="/privacidade" className="menu-link" onClick={closeMenu}>Privacidade</Link>
          <Link to="/minha-conta" className="menu-link minha-conta" onClick={closeMenu}>Minha Conta</Link>
          <Link to="/cadastro" className="menu-link cadastro" onClick={closeMenu}>Cadastro</Link>
          <Link to="/postos" className="menu-link" onClick={closeMenu}>Postos Participantes</Link>
          <Link to="/" className="menu-link" onClick={closeMenu}>Sair/Desconectar</Link>
          <div className="menu-footer">
            <Link to="/area-restrita" className="restricted-link" onClick={closeMenu}>
              <FontAwesomeIcon icon={faUserSecret} className="icon-discreet" />
            </Link>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;

