import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faUsers, faTrophy, faTrash } from '@fortawesome/free-solid-svg-icons'; // Importa o ícone da lixeira
import { auth, db } from '../firebaseConfig';
import { getDoc, doc, setDoc } from 'firebase/firestore';
import './DashRestrito.css';

const DashRestrito = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const user = auth.currentUser;
      if (user && user.uid === 'h4UgJPJpgqfPc3qW3YVQxNuac4l1') {
        setIsAdmin(true);
      }

      const docRef = doc(db, 'config', 'maintenance');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsMaintenanceMode(docSnap.data().isMaintenanceMode);
      }
    };

    checkUser();
  }, []);

  const toggleMaintenanceMode = async () => {
    const newMode = !isMaintenanceMode;
    setIsMaintenanceMode(newMode);
    await setDoc(doc(db, 'config', 'maintenance'), { isMaintenanceMode: newMode });
  };

  return (
    <div className="dash-container">
      <div className="dash-content-box">
        <h1>Bem-vindo à Área Restrita</h1>
        <p>Esta é uma área restrita acessível apenas para usuários autorizados.</p>
        <div className="button-container">
          <Link to="/realizar-sorteio" className="dash-button">
            <FontAwesomeIcon icon={faGift} className="button-icon" />
            Realizar Sorteio
          </Link>
          <Link to="/participantes" className="dash-button">
            <FontAwesomeIcon icon={faUsers} className="button-icon" />
            Participantes
          </Link>
          <Link to="/ganhador" className="dash-button">
            <FontAwesomeIcon icon={faTrophy} className="button-icon" />
            Ganhador
          </Link>
          {isAdmin && (
            <Link to="/delete-user-data" className="dash-button">
              <FontAwesomeIcon icon={faTrash} className="button-icon" /> {/* Ícone da lixeira */}
              Deletar Dados do Cliente
            </Link>
          )}
        </div>
        {isAdmin && (
          <div className="maintenance-toggle">
            <label>
              <input
                type="checkbox"
                checked={isMaintenanceMode}
                onChange={toggleMaintenanceMode}
              />
              Colocar sistema em manutenção?
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default DashRestrito;

