import React, { useState } from 'react';
import { collection, query, where, getDocs, writeBatch } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const DeleteUserData = () => {
  const [email, setEmail] = useState('');
  const [confirmation, setConfirmation] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleDelete = async () => {
    if (!email) {
      alert('Por favor, insira um email.');
      return;
    }

    try {
      // Deletar cupons
      const cuponsRef = collection(db, 'cupons');
      const cuponsQuery = query(cuponsRef, where('email', '==', email));
      const cuponsSnapshot = await getDocs(cuponsQuery);
      const batch = writeBatch(db);
      cuponsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      // Deletar cliente
      const clientesRef = collection(db, 'clientes');
      const clientesQuery = query(clientesRef, where('email', '==', email));
      const clientesSnapshot = await getDocs(clientesQuery);
      clientesSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });

      await batch.commit();
      alert('Documentos deletados com sucesso.');
      setEmail('');
      setConfirmation(false);
    } catch (error) {
      console.error('Erro ao deletar documentos: ', error);
      alert('Erro ao deletar documentos.');
    }
  };

  const handleConfirm = () => {
    setConfirmation(true);
  };

  const handleCancel = () => {
    setConfirmation(false);
  };

  return (
    <div>
      <h2>Deletar Dados do Cliente</h2>
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Digite o email do cliente"
      />
      <button onClick={handleConfirm}>Deletar</button>

      {confirmation && (
        <div>
          <p>Tem certeza de que deseja deletar todos os dados deste cliente?</p>
          <button onClick={handleDelete}>Sim</button>
          <button onClick={handleCancel}>Não</button>
        </div>
      )}
    </div>
  );
};

export default DeleteUserData;

