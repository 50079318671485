// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Importa Storage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBR5tQ0unJbvfkwcnMkYdDCXoAMoqpogNM",
  authDomain: "postos3r-98df4.firebaseapp.com",
  projectId: "postos3r-98df4",
  storageBucket: "postos3r-98df4.appspot.com",
  messagingSenderId: "334890289649",
  appId: "1:334890289649:web:4e87e18b0be435d5ef5c50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get the Firebase Auth object
const auth = getAuth(app);

// Setup Google provider
const googleProvider = new GoogleAuthProvider();

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { auth, app, googleProvider, db, storage };
