import React, { useEffect, useState } from 'react';
import './MinhaConta.css';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit, faUpload, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import LoginModal from './LoginModal'; // Importar o modal de login

function MinhaConta() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [tel, setTel] = useState(''); // Estado para o telefone/WhatsApp
  const [showLoginModal, setShowLoginModal] = useState(false); // Estado para controlar a exibição do modal
  const [editing, setEditing] = useState(false); // Estado para controlar a edição
  const [profileImage, setProfileImage] = useState(null); // Estado para a imagem de perfil
  const [profileImageUrl, setProfileImageUrl] = useState(''); // URL da imagem de perfil

  const navigate = useNavigate();

  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (!user) {
      setShowLoginModal(true); // Mostrar o modal se o usuário não estiver logado
      return;
    }
    const userDoc = await getDoc(doc(db, 'clientes', user.uid));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setNome(userData.nome);
      setEmail(userData.email);
      setCpf(userData.cpf);
      setTel(userData.tel || ''); // Obter o telefone/WhatsApp
      if (userData.profileImageUrl) {
        setProfileImageUrl(userData.profileImageUrl);
      }
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleVoltar = () => {
    navigate('/inicio');
  };

  const handleEdit = async () => {
    const user = auth.currentUser;
    if (user && email) {
      await updateDoc(doc(db, 'clientes', user.uid), { email, tel });
      setEditing(false);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (profileImage) {
      const user = auth.currentUser;
      const storageRef = ref(storage, `profileImages/${user.uid}`);
      await uploadBytes(storageRef, profileImage);
      const downloadURL = await getDownloadURL(storageRef);
      await updateDoc(doc(db, 'clientes', user.uid), { profileImageUrl: downloadURL });
      setProfileImageUrl(downloadURL);
      setProfileImage(null);
    }
  };

  const handleLogout = async () => {
    await auth.signOut();
    navigate('/');
  };

  return (
    <div className="minha-conta-container">
    
      <div className="conta-info">
<h1>Minha Conta</h1>
        {profileImageUrl && <img src={profileImageUrl} alt="Profile" className="profile-image" />}
        <div className="upload-section">
          <input type="file" onChange={handleFileChange} />
          <button type="button" className="upload-button" onClick={handleUpload}>
            <FontAwesomeIcon icon={faUpload} /> Upload imagem de perfil
          </button>
        </div>
        <div className="conta-info-item">
          <span className="conta-label">Nome:</span>
          <span>{nome}</span>
        </div>
        <div className="conta-info-item">
          <span className="conta-label">Email:</span>
          {editing ? (
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="edit-input"
            />
          ) : (
            <span>{email}</span>
          )}
        </div>
        <div className="conta-info-item">
          <span className="conta-label">CPF:</span>
          <span>{cpf}</span>
        </div>
        <div className="conta-info-item">
          <span className="conta-label">Telefone/WhatsApp:</span>
          {editing ? (
            <input
              type="tel"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              className="edit-input"
            />
          ) : (
            <span>{tel}</span>
          )}
        </div>
        {editing ? (
          <button type="button" className="save-button" onClick={handleEdit}>
            Salvar
          </button>
        ) : (
          <button type="button" className="edit-button" onClick={() => setEditing(true)}>
            <FontAwesomeIcon icon={faEdit} /> Editar Perfil
          </button>
        )}
        
        <button type="button" className="logout-button" onClick={handleLogout}>
          <FontAwesomeIcon icon={faSignOutAlt} /> Sair
        </button>
      </div>
      {showLoginModal && <LoginModal onClose={() => { setShowLoginModal(false); navigate('/inicio'); }} onLoginSuccess={fetchUserData} />} {/* Exibir o modal de login */}
    </div>
  );
}

export default MinhaConta;


