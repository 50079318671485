import React from 'react';
import './Termos.css';

const Termos = () => {
  return (
    <div className="termos-container">
      <div className="termos-content">
        <h1>Termos e Condições</h1>
        <p>Bem-vindo ao Sorteio Kwid Intense 0km 2024 promovido pela rede de postos de combustíveis 3R.</p>
        <p>
          A cada R$100,00 abastecidos em qualquer tipo de combustível, o cliente tem direito a 1 cupom para participar do sorteio que será realizado no dia 28 de dezembro de 2024, baseado na Loteria Federal. O valor é cumulativo até a data do sorteio.
        </p>
        <p>
          Para participar, é necessário se cadastrar no sistema e fazer o upload das notas fiscais e do valor correspondente. Toda vez que acumular R$100,00 abastecidos, o participante pode gerar um cupom.
        </p>
        <p>
          No dia do sorteio, os números sorteados na Loteria Federal serão inseridos na plataforma e verificaremos se algum dos participantes tem um cupom com esses números. Se sim, ele será o vencedor do prêmio.
        </p>
        <p>
          Se nenhuma das 5 sequências sorteadas pela Loteria Federal coincidir com os cupons gerados, será realizado um sorteio aleatório dos cupons disponíveis no sistema. Esse sorteio será feito automaticamente pelo sistema, sem qualquer interferência humana, para evitar fraudes. Os cupons armazenados no banco de dados serão sorteados automaticamente, resultando em um vencedor.
        </p>
        <p>
          É essencial que os participantes guardem e mantenham todas as notas fiscais originais que foram enviadas ao sistema. O vencedor deverá apresentar pessoalmente o documento CPF e RG com foto, bem como as notas fiscais originais utilizadas para gerar os cupons no sistema. Caso contrário, não receberá o prêmio.
        </p>
<p>
          Na entrega do prêmio, o vencedor poderá receber o Kwid Intense 0km 2024, ou pode optar por receber o valor de R$50.000 (conquenta mil reais) em dinheiro PIX.
        </p>

        
        <p>Boa sorte a todos os participantes!</p>
      </div>
    </div>
  );
};

export default Termos;
