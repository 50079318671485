import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Cadastro from './components/Cadastro';
import Inicio from './components/Inicio';
import CadastrarNota from './components/CadastrarNota';
import HistoricoDeNotas from './components/HistoricoDeNotas';
import GerarCupons from './components/GerarCupons';
import MeusCupons from './components/MeusCupons';
import Suporte from './components/Suporte';
import MinhaConta from './components/MinhaConta';
import Termos from './components/Termos';
import Privacidade from './components/Privacidade';
import Postos from './components/Postos';
import Footer from './components/Footer';
import RestrictedArea from './components/RestrictedArea'; 
import DashRestrito from './components/DashRestrito'; 
import RealizarSorteio from './components/RealizarSorteio';
import Participantes from './components/Participantes';
import Ganhador from './components/Ganhador';
import Manutencao from './components/Manutencao';
import DeleteUserData from './components/DeleteUserData'; // Importe o novo componente
import { auth, db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import './App.css'; // Importar o CSS global

function App() {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      const docRef = doc(db, 'config', 'maintenance');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsMaintenanceMode(docSnap.data().isMaintenanceMode);
      }
    };

    const checkUser = async () => {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const isAdmin = user.uid === 'h4UgJPJpgqfPc3qW3YVQxNuac4l1';
          setIsAdmin(isAdmin);
        } else {
          setIsAdmin(false);
        }
        setLoading(false);
      });
    };

    fetchMaintenanceStatus();
    checkUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <Router>
        <Header />
        <div className="content">
          <Routes>
            {isMaintenanceMode && !isAdmin ? (
              <>
                <Route path="/area-restrita" element={<RestrictedArea />} />
                <Route path="*" element={<Manutencao />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/cadastro" element={<Cadastro />} />
                <Route path="/inicio" element={<Inicio />} />
                <Route path="/cadastrar-nota" element={<CadastrarNota />} />
                <Route path="/historico-de-notas" element={<HistoricoDeNotas />} />
                <Route path="/gerar-cupons" element={<GerarCupons />} />
                <Route path="/meus-cupons" element={<MeusCupons />} />
                <Route path="/suporte" element={<Suporte />} />
                <Route path="/minha-conta" element={<MinhaConta />} />
                <Route path="/termos" element={<Termos />} />
                <Route path="/privacidade" element={<Privacidade />} />
                <Route path="/postos" element={<Postos />} />
                <Route path="/area-restrita" element={<RestrictedArea />} />
                <Route path="/dashrestrito" element={<DashRestrito />} />
                <Route path="/realizar-sorteio" element={<RealizarSorteio />} />
                <Route path="/participantes" element={<Participantes />} />
                <Route path="/ganhador" element={<Ganhador />} />
                <Route path="/delete-user-data" element={<DeleteUserData />} /> {/* Adicione a nova rota */}
              </>
            )}
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

