import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import './RestrictedArea.css';

const RestrictedArea = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  
  const allowedUsers = [
    'h4UgJPJpgqfPc3qW3YVQxNuac4l1',
    'KOKYdP0goKdkbWb7vYjLBJXa5xs2'
  ];

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (allowedUsers.includes(user.uid)) {
        navigate('/dashrestrito');
      } else {
        alert('Acesso negado. Usuário não autorizado.');
        await auth.signOut();
      }
    } catch (error) {
      alert('Erro no login: ' + error.message);
    }
  };

  return (
    <div className="restricted-container">
      <div className="restricted-content-box">
        <h2>Área Restrita</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            required
            className="restricted-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            required
            className="restricted-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="restricted-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default RestrictedArea;
