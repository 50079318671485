import React, { useState } from 'react';
import './Suporte.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Suporte = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [posto, setPosto] = useState('');
  const [mensagem, setMensagem] = useState('');

  const handleEnviar = (e) => {
    e.preventDefault();
    const whatsappMessage = `Nome: ${nome}%0AEmail: ${email}%0APosto: ${posto}%0AMensagem: ${mensagem}`;
    const whatsappUrl = `https://wa.me/558899656441?text=${whatsappMessage}`;
    window.open(whatsappUrl, '_blank');
  };

  const postos = [
    'Posto 3R',
    'Auto Posto Novo Juazeiro',
    'Aeroposto',
    'Auto Posto Limoeiro',
    'Auto Posto Raimundo Neto',
    'Auto Posto Mãe de Deus',
    'Auto Posto Ricardão'
  ];

  return (
    <div className="suporte-container">
      <form className="suporte-form" onSubmit={handleEnviar}>
        <h1>Contato e Atendimento</h1>
        <p>Após enviar a mensagem, uma conversa por WhatsApp será aberta para iniciar o atendimento.</p>
        <input
          type="text"
          placeholder="Nome"
          required
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <select
          required
          value={posto}
          onChange={(e) => setPosto(e.target.value)}
          className="select-posto"
        >
          <option value="" disabled>Selecione o Posto</option>
          {postos.map((posto, index) => (
            <option key={index} value={posto}>{posto}</option>
          ))}
        </select>
        <textarea
          placeholder="Sua mensagem"
          required
          value={mensagem}
          onChange={(e) => setMensagem(e.target.value)}
        />
        <button type="submit" className="enviar-button">
          <FontAwesomeIcon icon={faWhatsapp} /> Enviar
        </button>
      </form>
    </div>
  );
};

export default Suporte;




