import React, { useState } from 'react';
import './LoginModal.css';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ onClose, onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onLoginSuccess(); // Chamar a função de callback para atualizar os dados do usuário
      onClose();
      navigate('/minha-conta'); // Redirecionar para a página Minha Conta após o login bem-sucedido
    } catch (error) {
      alert('Erro no login: ' + error.message);
    }
  };

  const handleCancel = () => {
    onClose();
    navigate('/');
  };

  return (
    <div className="login-modal-overlay">
      <div className="login-modal">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Senha"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <div className="modal-buttons">
            <button type="submit" className="login-button">
              <FontAwesomeIcon icon={faSignInAlt} /> Login
            </button>
            <button type="button" className="close-button" onClick={handleCancel}>
              <FontAwesomeIcon icon={faTimes} /> Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginModal;



