import React, { useState } from 'react';
import './Cadastro.css';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUserPlus } from '@fortawesome/free-solid-svg-icons';

function Cadastro() {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [tel, setTel] = useState('');
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleCadastro = async (event) => {
    event.preventDefault();
    if (senha !== confirmarSenha) {
      alert('As senhas não coincidem!');
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, senha);
      const user = userCredential.user;
      await setDoc(doc(db, 'clientes', user.uid), {
        nome: nome,
        email: email,
        cpf: cpf,
        tel: tel,
        dataCadastro: new Date().toISOString(),
        creditos: 0,
        cupons: 0,
        saldoNotas: 0,
        totalNotas: 0,
        abastecimentos: {}
      });
      setShowModal(true);
    } catch (error) {
      console.error("Erro no cadastro:", error);
      alert('Erro no cadastro: ' + error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/');
  };

  const handleVoltar = () => {
    navigate('/');
  };

  return (
    <div className="cadastro-container">
      
      <form className="cadastro-form" onSubmit={handleCadastro}>
        <h1>Cadastre-se</h1>
        <input
          type="text"
          placeholder="Nome Completo"
          required
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="CPF"
          required
          value={cpf}
          onChange={(e) => setCpf(e.target.value)}
        />
        <input
          type="tel"
          placeholder="Telefone/WhatsApp"
          value={tel}
          onChange={(e) => setTel(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          required
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirmar Senha"
          required
          value={confirmarSenha}
          onChange={(e) => setConfirmarSenha(e.target.value)}
        />
        <button type="submit" className="cadastro-button">
          <FontAwesomeIcon icon={faUserPlus} /> Cadastrar
        </button>
        <button type="button" className="cadastro-login-button" onClick={handleVoltar}>
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Login
        </button>
      </form>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Cadastro efetuado com sucesso!</h2>
            <button onClick={handleCloseModal} className="modal-button">
              Fazer login
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cadastro;



