import React, { useEffect, useState } from 'react';
import './Inicio.css';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faTicketAlt, faMoneyBillWave, faPlusCircle, faCoins, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function Inicio() {
  const [userName, setUserName] = useState('');
  const [totalNotas, setTotalNotas] = useState(0);
  const [saldoNotas, setSaldoNotas] = useState(0);
  const [cupons, setCupons] = useState(0);
  const [creditos, setCreditos] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'clientes', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.nome);
          setTotalNotas(userData.totalNotas || 0);
          setSaldoNotas(userData.saldoNotas || 0);
          setCupons(userData.cupons || 0);
          setCreditos(userData.creditos || 0);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleVerCupons = () => {
    navigate('/meus-cupons');
  };

  const handleGerarCupons = () => {
    navigate('/gerar-cupons');
  };

  const handleCadastrarNota = () => {
    navigate('/cadastrar-nota');
  };

  const handleVerNotas = () => {
    navigate('/historico-de-notas');
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate('/');
    });
  };

  return (
    <div className="inicio-container">
      <div className="content-box">
        <h1>Bem-vindo, {userName}!</h1>
        <div className="painel">
          <div className="painel-item">
            <FontAwesomeIcon icon={faReceipt} className="painel-icon" />
            <div className="painel-info">
              <h2>Total de Notas</h2>
              <p>R${totalNotas.toFixed(2)}</p>
              <button onClick={handleVerNotas} className="ver-notas-button">Ver Notas</button>
            </div>
          </div>
          <div className="painel-item">
            <FontAwesomeIcon icon={faMoneyBillWave} className="painel-icon" />
            <div className="painel-info">
              <h2>Saldo em Notas Cadastradas</h2>
              <p>R${saldoNotas.toFixed(2)}</p>
              <p>Abasteça mais R${(100 - saldoNotas).toFixed(2)} para ganhar mais um crédito</p>
            </div>
          </div>
          <div className="painel-item">
            <FontAwesomeIcon icon={faTicketAlt} className="painel-icon" />
            <div className="painel-info">
              <h2>Cupons</h2>
              <p>{cupons}</p>
              <button onClick={handleVerCupons} className="ver-cupons-button">Ver meus cupons</button>
            </div>
          </div>
          <div className="painel-item">
            <FontAwesomeIcon icon={faCoins} className="painel-icon" />
            <div className="painel-info">
              <h2>Créditos</h2>
              <p>{creditos}</p>
              <button onClick={handleGerarCupons} className="gerar-cupons-button">Gerar cupons</button>
            </div>
          </div>
        </div>
        <div className="buttons-container">
          <button onClick={handleCadastrarNota} className="cadastrar-nota-button">
            <FontAwesomeIcon icon={faPlusCircle} /> Cadastrar Nota
          </button>
          <button onClick={handleLogout} className="logout-button">
            <FontAwesomeIcon icon={faSignOutAlt} /> Sair/Desconectar
          </button>
        </div>
      </div>
    </div>
  );
}

export default Inicio;

