import React from 'react';
import './Manutencao.css';

const Manutencao = () => {
  return (
    <div className="manutencao-container">
      <h1>Sistema em Manutenção</h1>
      <p>Favor entrar em contato com o desenvolvedor.</p>
    </div>
  );
};

export default Manutencao;
