import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, deleteDoc, query, where } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faInfoCircle, faImage, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './Participantes.css';

const Participantes = () => {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [notesPerPage] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchClientes = async () => {
      const clientesSnapshot = await getDocs(collection(db, 'clientes'));
      const clientesList = clientesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setClientes(clientesList);
      setFilteredClientes(clientesList);
    };

    fetchClientes();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = clientes.filter(cliente =>
      cliente.nome.toLowerCase().includes(query) || cliente.email.toLowerCase().includes(query)
    );
    setFilteredClientes(filtered);
  };

  const maskCPF = (cpf) => {
    return cpf.slice(0, -5).replace(/\d/g, '*') + cpf.slice(-5);
  };

  const handleVerDetalhes = (cliente) => {
    setSelectedCliente(cliente);
  };

  const handleVoltar = () => {
    setSelectedCliente(null);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleDelete = async (clienteId) => {
    if (window.confirm('Tem certeza que deseja deletar este participante? Esta ação não pode ser desfeita.')) {
      try {
        const userDocRef = doc(db, 'clientes', clienteId);

        // Deletar os cupons associados ao cliente
        const cuponsQuery = query(collection(db, 'cupons'), where('uid', '==', clienteId));
        const cuponsSnapshot = await getDocs(cuponsQuery);
        const deletePromises = cuponsSnapshot.docs.map(cupomDoc => deleteDoc(cupomDoc.ref));
        await Promise.all(deletePromises);

        // Verificar se todos os cupons foram deletados
        const remainingCuponsSnapshot = await getDocs(cuponsQuery);
        if (remainingCuponsSnapshot.empty) {
          console.log('Todos os cupons foram deletados com sucesso.');
        } else {
          console.error('Alguns cupons não foram deletados.');
        }

        // Deletar o documento do cliente
        await deleteDoc(userDocRef);

        // Atualizar a lista de clientes
        setClientes(clientes.filter(cliente => cliente.id !== clienteId));
        setFilteredClientes(filteredClientes.filter(cliente => cliente.id !== clienteId));
        alert('Cliente e seus cupons deletados com sucesso!');
      } catch (error) {
        console.error('Erro ao deletar cliente:', error);
        alert('Erro ao deletar cliente. Por favor, tente novamente.');
      }
    }
  };

  // Calcular o índice dos itens atuais
  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = selectedCliente ? Object.values(selectedCliente.abastecimentos).slice(indexOfFirstNote, indexOfLastNote) : [];

  const pageNumbers = [];
  if (selectedCliente) {
    for (let i = 1; i <= Math.ceil(Object.values(selectedCliente.abastecimentos).length / notesPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <div className="participantes-container">
      <button className="back-button" onClick={() => navigate('/dashrestrito')}>
        <FontAwesomeIcon icon={faArrowLeft} className="button-icon" />
        Voltar para Dashboard
      </button>
      <div className="content-box">
        <h2>Participantes</h2>
        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch} className="search-icon" />
          <input
            type="text"
            placeholder="Buscar por nome ou email"
            value={searchQuery}
            onChange={handleSearch}
            className="search-input"
          />
        </div>
        {selectedCliente ? (
          <div className="detalhes-container">
            <button className="back-button" onClick={handleVoltar}>
              <FontAwesomeIcon icon={faArrowLeft} className="button-icon" />
              Voltar
            </button>
            <h3>Notas de {selectedCliente.nome}</h3>
            <div className="scrollable-table">
              <table>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Posto</th>
                    <th>Valor</th>
                    <th>Imagem</th>
                  </tr>
                </thead>
                <tbody>
                  {currentNotes.map((nota, index) => (
                    <tr key={index}>
                      <td>{new Date(nota.data).toLocaleDateString()}</td>
                      <td>{nota.posto}</td>
                      <td>{nota.valor}</td>
                      <td>
                        <a href={nota.imagem} target="_blank" rel="noopener noreferrer" className="imagem-button">
                          <FontAwesomeIcon icon={faImage} className="button-icon" />
                          Ver Imagem
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              {pageNumbers.map(number => (
                <button key={number} onClick={() => paginate(number)} className="page-number">
                  {number}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className="scrollable-table">
            <table className="participantes-tabela">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>E-mail</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {filteredClientes.map(cliente => (
                  <tr key={cliente.id}>
                    <td>{cliente.nome}</td>
                    <td>{maskCPF(cliente.cpf)}</td>
                    <td>{cliente.email}</td>
                    <td>
                      <div className="action-buttons">
                        <button className="detalhes-button" onClick={() => handleVerDetalhes(cliente)}>
                          <FontAwesomeIcon icon={faInfoCircle} className="button-icon" />
                          Ver +
                        </button>
                        <button className="delete-button" onClick={() => handleDelete(cliente.id)}>
                          <FontAwesomeIcon icon={faTrashAlt} className="button-icon" />
                          Deletar
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Participantes;


