import React from 'react';
import './Privacidade.css';

const Privacidade = () => {
  return (
    <div className="privacidade-container">
      <div className="privacidade-content">
        <h1>Política de Privacidade</h1>
        <p>BBem-vindo ao Sorteio Kwid Intense 0km 2024 promovido pela rede de postos de combustíveis 3R.</p>
        <p>Os dados de cadastro dos usuários coletados são utilizados única e exclusivamente para o sorteio e não serão compartilhados com terceiros. Todos os dados são guardados de forma criptografada para garantir a segurança e a privacidade dos participantes.</p>
        <h2>Coleta de Informações</h2>
        <p>Coletamos informações pessoais fornecidas diretamente por você ao se cadastrar em nosso sorteio. As informações coletadas incluem nome, endereço de e-mail, número de telefone e informações de compra.</p>
        <h2>Uso das Informações</h2>
        <p>As informações coletadas são utilizadas para:</p>
        <ul>
          <li>Gerenciar sua participação no sorteio;</li>
          <li>Entrar em contato com você em relação ao sorteio;</li>
          <li>Garantir a segurança e a integridade do sorteio;</li>
        </ul>
        <h2>Proteção das Informações</h2>
        <p>Adotamos medidas técnicas e organizacionais para proteger suas informações pessoais contra perda, uso indevido e acesso não autorizado, divulgação, alteração e destruição. Seus dados são guardados de forma criptografada.</p>
        <h2>Compartilhamento de Informações</h2>
        <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando exigido por lei ou para proteger nossos direitos.</p>
        <h2>Seus Direitos</h2>
        <p>Você tem o direito de acessar, corrigir ou excluir suas informações pessoais. Para exercer esses direitos, entre em contato conosco através do e-mail fornecido no cadastro.</p>
        <h2>Alterações nesta Política de Privacidade</h2>
        <p>Podemos atualizar nossa Política de Privacidade periodicamente. Recomendamos que você reveja esta página regularmente para quaisquer alterações.</p>
        <h2>Contato</h2>
        <p>Se você tiver quaisquer perguntas ou preocupações sobre esta Política de Privacidade, entre em contato conosco através do e-mail fornecido no cadastro.</p>
      </div>
    </div>
  );
};

export default Privacidade;


