import React, { useState } from 'react';
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      alert('Login realizado com sucesso!');
      navigate('/inicio');
    } catch (error) {
      alert('Erro no login: ' + error.message);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleForgotPassword = async () => {
    if (email) {
      try {
        await sendPasswordResetEmail(auth, email);
        alert('E-mail de recuperação enviado.');
      } catch (error) {
        alert('Erro ao enviar e-mail de recuperação: ' + error.message);
      }
    } else {
      alert('Por favor, insira seu e-mail para recuperação de senha.');
    }
  };

  return (
    <div className="home-container-custom">
      <img src={require('./sorteio.png')} alt="Posto" className="main-image-custom" />
      <div className="form-container-custom">
        <form className="login-form-custom" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            required
            className="input-field-custom"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="password-field-custom">
            <input
              type={passwordShown ? 'text' : 'password'}
              placeholder="Senha"
              required
              className="input-field-custom"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FontAwesomeIcon
              icon={passwordShown ? faEyeSlash : faEye}
              onClick={togglePasswordVisibility}
              className="toggle-password-visibility"
            />
          </div>
          <button type="submit" className="login-button-custom">
            <FontAwesomeIcon icon={faSignInAlt} /> Login / Entrar
          </button>
        </form>
        <div className="password-recovery-link">
          <a onClick={handleForgotPassword} className="forgot-password-link">
            Esqueci Minha Senha
          </a>
        </div>
        <Link to="/cadastro" className="register-button-custom">
          Cadastre-se e Participe!
        </Link>
      </div>
    </div>
  );
}

export default Home;



