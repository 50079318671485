import React, { useState } from 'react';
import './CadastrarNota.css';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function CadastrarNota() {
  const [nota, setNota] = useState('');
  const [posto, setPosto] = useState('');
  const [data, setData] = useState(new Date());
  const [imagem, setImagem] = useState(null);
  const [extrato, setExtrato] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isCadastroSuccess, setIsCadastroSuccess] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');

  const [postos] = useState([
    'Posto 3r',
    'Auto Posto Novo Juazeiro',
    'Aeroposto',
    'Auto Posto Limoeiro',
    'Auto Posto Raimundo Neto',
    'Auto Posto Mãe de Deus',
    'Auto Posto Ricardão'
  ]);

  const navigate = useNavigate();

  const parseNota = (nota) => {
    return parseFloat(nota.replace(',', '.'));
  };

  const handleCadastroNota = async (event) => {
    event.preventDefault();
    const user = auth.currentUser;
    if (user && imagem && extrato) {
      const extratoDocRef = doc(db, 'estratonotas', extrato);
      const extratoDoc = await getDoc(extratoDocRef);

      if (extratoDoc.exists()) {
        setModalMessage("Esta Nota já foi cadastrada no Sistema");
        setShowModal(true);
        setIsCadastroSuccess(false);
        return;
      }

      const userDocRef = doc(db, 'clientes', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', userData);

        const valorNota = parseNota(nota);
        const novoTotal = (userData.totalNotas || 0) + valorNota;
        const novoSaldo = (userData.saldoNotas || 0) + valorNota;
        const novosCreditos = Math.floor(novoSaldo / 100);
        const saldoRestante = novoSaldo % 100;

        console.log('Novo total:', novoTotal);
        console.log('Novo saldo:', novoSaldo);
        console.log('Novos créditos:', novosCreditos);
        console.log('Saldo restante:', saldoRestante);

        const imageRef = ref(storage, `notas/${user.uid}/${Date.now()}_${imagem.name}`);
        await uploadBytes(imageRef, imagem);
        const imageUrl = await getDownloadURL(imageRef);

        const novoAbastecimento = {
          valor: valorNota,
          posto: posto,
          data: data.toISOString(),
          imagem: imageUrl,
          extrato: extrato
        };

        await updateDoc(userDocRef, {
          totalNotas: novoTotal,
          saldoNotas: saldoRestante,
          creditos: increment(novosCreditos),
          [`abastecimentos.${Date.now()}`]: novoAbastecimento
        });

        await setDoc(extratoDocRef, { uid: user.uid });

        setModalMessage(`Cadastro efetuado com sucesso!\n\nVocê ganhou ${novosCreditos} crédito(s)!`);
        setShowModal(true);
        setIsCadastroSuccess(true);
      } else {
        console.error('User document does not exist');
      }
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setImagem(event.target.files[0]);
      setUploadMessage(`Arquivo "${event.target.files[0].name}" anexado com sucesso.`);
    } else {
      setUploadMessage('');
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalMessage('');
    if (isCadastroSuccess) {
      navigate('/inicio');
    }
  };

  const handleVoltarInicio = () => {
    navigate('/inicio');
  };

  return (
    <div className="cadastrar-nota-container">
      <div className="form-container">
        <h1>Cadastrar Nota</h1>
        <form className="cadastrar-nota-form" onSubmit={handleCadastroNota}>
          <input
            type="text"
            placeholder="Valor da Nota"
            required
            value={nota}
            onChange={(e) => setNota(e.target.value)}
          />
          <select
            required
            value={posto}
            onChange={(e) => setPosto(e.target.value)}
            className="select-posto"
          >
            <option value="" disabled>Selecione o Posto</option>
            {postos.map((posto, index) => (
              <option key={index} value={posto}>{posto}</option>
            ))}
          </select>
          <DatePicker
            selected={data}
            onChange={(date) => setData(date)}
            dateFormat="dd/MM/yyyy"
            className="datepicker"
          />
          <input
            type="text"
            placeholder="Extrato N. (da nota)"
            required
            value={extrato}
            onChange={(e) => setExtrato(e.target.value)}
          />
          <div className="extrato-reference">
            <img src={require('./extrato.png')} alt="Referência de Extrato" className="extrato-image" />
          </div>
          <div className="file-upload">
            <input type="file" accept="image/*" onChange={handleFileChange} required />
            <FontAwesomeIcon icon={faUpload} className="upload-icon" />
            {imagem && <span></span>}
          </div>
          {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
          <button type="submit" className="cadastrar-nota-button">
            <FontAwesomeIcon icon={faPlusCircle} /> Cadastrar Nota
          </button>
          <button type="button" className="voltar-inicio-button" onClick={handleVoltarInicio}>
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
          </button>
        </form>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>{modalMessage}</h2>
            <button onClick={handleModalClose} className="modal-button">
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CadastrarNota;



