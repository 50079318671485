import React, { useState } from 'react';
import './CadastrarNota.css';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../firebaseConfig';
import { doc, getDoc, setDoc, updateDoc, increment, query, where, getDocs, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

function CadastrarNota() {
  const [nota, setNota] = useState('');
  const [posto, setPosto] = useState('');
  const [data, setData] = useState(new Date());
  const [imagem, setImagem] = useState(null);
  const [extrato, setExtrato] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isCadastroSuccess, setIsCadastroSuccess] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [formattedNota, setFormattedNota] = useState('');

  const [postos] = useState([
    'Posto 3r',
    'Auto Posto Novo Juazeiro',
    'Aeroposto',
    'Auto Posto Limoeiro',
    'Auto Posto Raimundo Neto',
    'Auto Posto Mãe de Deus',
    'Auto Posto Ricardão'
  ]);

  const navigate = useNavigate();

  const parseNota = (nota) => {
    return parseFloat(nota.replace(',', '.'));
  };

  const handleCadastroNota = async () => {
    const user = auth.currentUser;
    if (user && imagem && extrato) {
      const extratoDocRef = doc(db, 'estratonotas', `${user.uid}_${extrato}`);
      const extratoDoc = await getDoc(extratoDocRef);

      if (extratoDoc.exists()) {
        setPopupMessage("Esta Nota já foi cadastrada no Sistema pelo seu usuário");
        setShowPopup(true);
        setIsCadastroSuccess(false);
        return;
      }

      const userDocRef = doc(db, 'clientes', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data:', userData);

        const valorNota = parseNota(nota);
        const novoTotal = (userData.totalNotas || 0) + valorNota;
        const novoSaldo = (userData.saldoNotas || 0) + valorNota;
        const novosCreditos = Math.floor(novoSaldo / 100);
        const saldoRestante = novoSaldo % 100;

        console.log('Novo total:', novoTotal);
        console.log('Novo saldo:', novoSaldo);
        console.log('Novos créditos:', novosCreditos);
        console.log('Saldo restante:', saldoRestante);

        const imageRef = ref(storage, `notas/${user.uid}/${Date.now()}_${imagem.name}`);
        await uploadBytes(imageRef, imagem);
        const imageUrl = await getDownloadURL(imageRef);

        const novoAbastecimento = {
          valor: valorNota,
          posto: posto,
          data: data.toISOString(),
          imagem: imageUrl,
          extrato: extrato
        };

        await updateDoc(userDocRef, {
          totalNotas: novoTotal,
          saldoNotas: saldoRestante,
          creditos: increment(novosCreditos),
          [`abastecimentos.${Date.now()}`]: novoAbastecimento
        });

        await setDoc(extratoDocRef, { uid: user.uid, extrato: extrato });

        setPopupMessage(`Cadastro efetuado com sucesso!\n\nVocê ganhou ${novosCreditos} crédito(s)!`);
        setShowPopup(true);
        setIsCadastroSuccess(true);
      } else {
        console.error('User document does not exist');
      }
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setImagem(event.target.files[0]);
      setUploadMessage(`Arquivo "${event.target.files[0].name}" anexado com sucesso.`);
    } else {
      setUploadMessage('');
    }
  };

  const handleNotaChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[0-9.,]+$/.test(value)) {
      setNota(value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const numericValue = parseNota(nota);
    if (isNaN(numericValue) || numericValue > 900) {
      alert('Por favor, insira um valor válido até 900.');
      return;
    }
    setFormattedNota(numericValue.toFixed(2).replace('.', ','));
    setIsConfirmPopupOpen(true);
  };

  const handleConfirm = () => {
    handleCadastroNota();
    setIsConfirmPopupOpen(false);
  };

  const handleReview = () => {
    setIsConfirmPopupOpen(false);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
    setPopupMessage('');
    if (isCadastroSuccess) {
      navigate('/inicio');
    }
  };

  const handleVoltarInicio = () => {
    navigate('/inicio');
  };

  return (
    <div className="cadastrar-nota-container">
      <div className="form-container">
        <h1>Cadastrar Nota</h1>
        <form className="cadastrar-nota-form" onSubmit={handleFormSubmit}>
          <input
            type="text"
            placeholder="Valor da Nota"
            required
            value={nota}
            onChange={handleNotaChange}
          />
          <select
            required
            value={posto}
            onChange={(e) => setPosto(e.target.value)}
            className="select-posto"
          >
            <option value="" disabled>Selecione o Posto</option>
            {postos.map((posto, index) => (
              <option key={index} value={posto}>{posto}</option>
            ))}
          </select>
          <DatePicker
            selected={data}
            onChange={(date) => setData(date)}
            dateFormat="dd/MM/yyyy"
            className="datepicker"
          />
          <input
            type="text"
            placeholder="Extrato N. (da nota)"
            required
            value={extrato}
            onChange={(e) => setExtrato(e.target.value)}
          />
          <div className="extrato-reference">
            <img src={require('./extrato.png')} alt="Referência de Extrato" className="extrato-image" />
          </div>
          <p className="bold">Envie uma foto do Cupom Fiscal abaixo:</p>
          <p style={{ marginTop: '0' }}>(O cupom deve aparecer inteiro na foto)</p>
          <div className="file-upload">
            <input type="file" accept="image/*" onChange={handleFileChange} required />
            <FontAwesomeIcon icon={faUpload} className="upload-icon" />
            {imagem && <span></span>}
          </div>
          {uploadMessage && <p className="upload-message">{uploadMessage}</p>}
          <button type="submit" className="cadastrar-nota-button">
            <FontAwesomeIcon icon={faPlusCircle} /> Cadastrar Nota
          </button>
          <button type="button" className="voltar-inicio-button" onClick={handleVoltarInicio}>
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar para Início
          </button>
        </form>
      </div>
      {isConfirmPopupOpen && (
        <div className="custom-popup">
          <div className="custom-popup-content">
            <h3>Confirmação de Valor</h3>
            <p>Você está incluindo uma nota no valor de {formattedNota}. Este valor está correto?</p>
            <p>Se o valor da nota fiscal não conferir com o valor digitado, os seus cupons gerados serão inválidos.</p>
            <p>Por favor, digite exatamente o valor como aparece na nota, incluindo vírgulas ou pontos, se houver.</p>
            <p><b>O Cupom Fiscal deve aparecer inteiro na foto, sem cortes</b></p>
            <div className="popup-buttons">
              <button onClick={handleConfirm} className="confirm-button">Confirmar</button>
              <button onClick={handleReview} className="review-button">Revisar Valor</button>
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="custom-popup">
          <div className="custom-popup-content">
            <h2>{popupMessage}</h2>
            <button onClick={handlePopupClose} className="popup-button">
              Fechar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CadastrarNota;


