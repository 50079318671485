import React from 'react';
import './Postos.css';

const postos = [
  { id: 1, nome: 'Auto Posto Raimundo Neto', imagem: '1.jpeg' },
  { id: 2, nome: 'Aeroposto', imagem: '2.jpeg' },
  { id: 3, nome: 'Auto Posto 3R', imagem: '3.jpeg' },
  { id: 4, nome: 'Auto Posto Limoeiro', imagem: '4.jpeg' },
  { id: 5, nome: 'Auto Posto E Pousada Mãe De Deus', imagem: '5.jpeg' },
  { id: 6, nome: 'Auto Posto Novo Juazeiro', imagem: '6.jpeg' },
  { id: 7, nome: 'Auto Posto Ricardão', imagem: '7.jpeg' },
];

const importImage = (imageName) => {
  try {
    return require(`./${imageName}`);
  } catch (error) {
    console.error(`Image not found: ${imageName}`);
    return null;
  }
};

const Postos = () => {
  return (
    <div className="postos-container">
      <div className="postos-content">
        <h1>Postos Participantes</h1>
        <p className="description">Abaixo estão listados os postos do grupo 3R que estão participando desta promoção.</p>
        <div className="postos-grid">
          {postos.map(posto => (
            <div key={posto.id} className="posto-item">
              <img src={importImage(posto.imagem)} alt={posto.nome} className="posto-image" />
              <h2 className="posto-nome">{posto.nome}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Postos;

